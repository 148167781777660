import { useRef, useState } from "react";
import SearchBar from "./components/SearchBar";
import Feature from "./components/Feature";

export const App = () => {
  const domain = "forexratesnow.xyz";
  const [query, setQuery] = useState("");
  const divRef = useRef<any>(null);
  const formRef = useRef<any>(null);

  return (
    <div className="w-screen h-screen overflow-none bg-app bg-cover bg-no-repeat">
      <div
        ref={divRef}
        className={`w-full h-full flex flex-col items-center justify-center z-50`}
      >
        <Feature domain={domain} />
        <form
          ref={formRef}
          className="z-10 w-full px-5"
          action={`https://${domain}/s`}
          method="GET"
        >
          <div className="relative flex flex-row w-full max-w-5xl mx-auto transition-all bg-white rounded-lg hover:shadow-2xl focus-within:shadow-2xl ring-1 ring-gray-500 focus-within:ring-gray-700">
            <SearchBar formRef={formRef} query={query} setQuery={setQuery} />
            <button
              className="flex flex-col items-center justify-center w-24 p-2 transition-all rounded-lg hover:bg-gray-100 active:outline-none focus:outline-none"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
