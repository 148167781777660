import jsonp from "jsonp";
import { useEffect, useState } from "react";

type Props = {
  domain: string;
};

const Feature = ({ domain }: Props) => {
  const mainCurrency = "usd";
  const pairCurrencies = [
    "eur",
    "gbp",
    "aud",
    "cad",
    "chf",
    "cny",
    "jpy",
    "nzd",
    "rub",
    "zar",
  ];
  const [prices, setPrices] = useState<any>({});

  useEffect(() => {
    Promise.all(
      pairCurrencies.map((currency) =>
        fetch(
          `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${mainCurrency}/${currency}.json`
        )
      )
    )
      .then((responses) =>
        Promise.all(
          responses.map(async (res) => {
            const json = await res.json();
            const object = {
              [Object.entries(json)[1][0]]: Object.entries(json)[1][1],
            };
            return object;
          })
        )
      )
      .then((currencies) => {
        const object = currencies.reduce((acc, cur) => {
          return { ...acc, ...cur };
        }, {});
        setPrices(object);
      });
  });

  return (
    <>
      <h1 className="-mt-48 text-lg font-extrabold mb-4 text-white">
        {domain.replace(".xyz", "")}
      </h1>
      <div className="mb-24 bg-white rounded-md shadow-xl overflow-hidden flex items-center justify-center min-h-48 p-5 w-96 max-w-full">
        <div className="flex-1 flex space-y-2 items-center flex-wrap justify-center">
          {Object.entries(prices).map(([currency, price]: any) => (
            <div
              key={currency}
              className="flex flex-col items-center justify-center w-1/2"
            >
              <div className="text-md font-bold text-gray-700">{price}</div>
              <div className="text-xs text-gray-500">
                {mainCurrency.toUpperCase()}/{currency.toUpperCase()}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Feature;
